import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #1f2b3a;
  color: white;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;  /* Ajouter un espace en bas */
  border-radius: 8px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h2`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Text = styled.p`
  margin-bottom: 10px;
  line-height: 1.6;
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 10px;
  line-height: 1.6;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;


const CGUCGV = () => {
    return (
        <Container>
            <Title>Conditions Générales d'Utilisation et de Vente (CGU/CGV) de X'plore</Title>
            <Text>
                Les présentes Conditions Générales d'Utilisation et de Vente (CGU/CGV) régissent l'utilisation de l'application mobile X'plore (ci-après "l'application") et les relations entre l'utilisateur (ci-après "l'utilisateur") et la société XPLORE (ci-après "la société"). En utilisant l'application, l'utilisateur accepte ces CGU/CGV.
            </Text>
            <SubTitle>1. Objet</SubTitle>
            <Text>
                L'Application X'plore est une application mobile de chasse au trésor qui permet aux utilisateurs de participer à des aventures interactives en résolvant des énigmes et en découvrant des lieux. Les présentes CGU/CGV définissent les conditions d'accès et d'utilisation de l'application, ainsi que les droits et obligations de l'utilisateur et de la société.
            </Text>
            <SubTitle>2. Création de compte</SubTitle>
            <Text>
                Pour utiliser l'application, l'utilisateur doit créer un compte en utilisant son adresse e-mail ou son compte Google. L'utilisateur s'engage à fournir des informations exactes et à jour. Chaque utilisateur ne peut créer qu'un seul compte.
            </Text>
            <SubTitle>3. Utilisation de l'application</SubTitle>
            <List>
                <ListItem>L'utilisateur s'engage à utiliser l'application conformément aux présentes CGU/CGV et à la législation en vigueur.</ListItem>
                <ListItem>L'utilisateur est responsable de la confidentialité de ses identifiants de connexion.</ListItem>
                <ListItem>L'utilisateur s'interdit toute utilisation frauduleuse ou abusive de l'application, y compris la création de comptes multiples.</ListItem>
                <ListItem>L'utilisateur accepte que l'application utilise sa caméra et ses données de localisation pour valider ses réponses aux énigmes et lui proposer des chasses au trésor à proximité.</ListItem>
            </List>
            <SubTitle>4. Récompenses</SubTitle>
            <List>
                <ListItem>Les récompenses sont attribuées aux utilisateurs ayant réussi une chasse au trésor en respectant les règles du jeu.</ListItem>
                <ListItem>La société se réserve le droit de vérifier l'intégrité des chasses au trésor avant d'attribuer les récompenses.</ListItem>
                <ListItem>Les récompenses ne sont pas échangeables contre leur valeur monétaire.</ListItem>
            </List>
            <SubTitle>5. Propriété intellectuelle</SubTitle>
            <List>
                <ListItem>Tous les éléments de l'application (textes, images, sons, logiciels, etc.) sont protégés par le droit d'auteur et appartiennent à la société.</ListItem>
                <ListItem>L'utilisateur s'interdit de reproduire, diffuser ou exploiter tout élément de l'application sans autorisation écrite préalable de la société.</ListItem>
            </List>
            <SubTitle>6. Signalement de problèmes et de contenu inapproprié</SubTitle>
            <Text>
                Si l'utilisateur rencontre un problème technique ou découvre du contenu inapproprié dans l'application, il peut le signaler directement par e-mail à l'adresse suivante : contact@xplore-app.com.
            </Text>
            <SubTitle>7. Assistance pour vos achats</SubTitle>
            <List>
            <ListItem>Si vous avez rencontré un problème avec l'achat d'un service ou d'un contenu sur X'plore, vous pouvez nous contacter pour en demander la récupération. Pour ce faire, veuillez envoyer un e-mail à l'adresse suivante : <a href="mailto:contact@xplore-app.com" style={{ color: 'white' }}>contact@xplore-app.com</a> avec les détails de votre achat et votre compte utilisateur.</ListItem>
            <ListItem>Bien que les achats sur X'plore ne soient généralement pas remboursables, nous comprenons que des situations exceptionnelles peuvent survenir. N'hésitez pas à nous contacter si vous rencontrez un problème, nous examinerons votre demande avec attention et chercherons une solution équitable.</ListItem>
            </List>
            <SubTitle>8. Responsabilité</SubTitle>
            <List>
                <ListItem>La société ne saurait être tenue responsable des dommages directs ou indirects résultant de l'utilisation de l'application.</ListItem>
                <ListItem>L'utilisateur est seul responsable de l'utilisation qu'il fait de l'application et des conséquences qui en découlent.</ListItem>
            </List>
            <SubTitle>9. Non-respect des CGU/CGV</SubTitle>
            <Text>
                En cas de non-respect des présentes CGU/CGV, la société se réserve le droit de suspendre ou de supprimer le compte de l'utilisateur, après confirmation du non-respect.
            </Text>
            <SubTitle>10. Modification des CGU/CGV</SubTitle>
            <Text>
                La société se réserve le droit de modifier les présentes CGU/CGV à tout moment. Les modifications seront notifiées aux utilisateurs via l'application ou par e-mail.
            </Text>
            <SubTitle>11. Droit applicable et juridiction compétente</SubTitle>
            <Text>
                Les présentes CGU/CGV sont soumises au droit français. Tout litige relatif à leur interprétation ou à leur exécution sera de la compétence exclusive des tribunaux français.
            </Text>

           
            
       </Container>
    );
}

export default CGUCGV;
